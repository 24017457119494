/*
 * This file has some common functions which is being used in diffrent page
 */

// This function used for getting the cookie from the browser
export function getusertokencookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

// This is the function for setting the cookie
export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

/*
 * This is an important function which used for building the pagination url
 * We are checkin if the parameter is being passed and adding it to the url
 */
export function buildurl(payload) {
  let url = "";
  if (payload.page && payload.page > 0) {
    url = url + `?page=${payload.page}`;
  }
  if (payload.limit && payload.limit > 0) {
    url = url + `&limit=${payload.limit}`;
  }
  if (payload.status && payload.status != "") {
    url = url + `&status=${payload.status}`;
  }
  if (payload.excel) {
    if (payload.run_id && payload.run_id != "") {
      url = url + `?run_id=${payload.run_id}`;
    }
  } else {
    if (payload.run_id && payload.run_id != "") {
      url = url + `&run_id=${payload.run_id}`;
    }
  }

  if (payload.parameter && payload.parameter != "") {
    url = url + `&Parameter_id=${payload.parameter}`;
  }
  if (payload.companycode && payload.companycode != "") {
    url = url + `&Company Code=${payload.companycode}`;
  }
  return url;
}
