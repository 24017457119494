/*
 * This is the entry file of the project.
 * When vue-cli will build the file it will create a html file and put all the
 * build script inside that html.
 */

import Vue from 'vue';
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './routes';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';

// basic config for axios where we can change the base URL
require('./plugins/axios');
Vue.use(VueAxios, axios);

// Created the Vue instance and mounted the App inside the html file
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

