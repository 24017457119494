/*
 * This is the module file where we are handling apis and storing data.
 * When you want to add a new api add the function inside action
 * and commit the data to the state with mutations.
 */

import axios from "../../plugins/axios";
import { getusertokencookie, setCookie, buildurl } from "../../plugins/common";
// Using FileSaver to save the zip folder coming from the backend
var FileSaver = require("file-saver");

/*
 * This axios defult function will fetch the token from cookie and
 * will add in the headers of every api call.
 */
axios.interceptors.request.use(
  function (config) {
    config.headers = { Authorization: "Bearer " + getusertokencookie("recon") };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// state is used to store the global data
const state = {
  userToken: "",
  isLogin: false,
  user: {},
  errorData: "",
  iserror: false,
  statusData: {},
  selectedRunId: {},
  stats: {},
};

// getters is used to fetch the data from store
const getters = {
  getUserToken: (state) => state.userToken,
  getisLogin: (state) => state.isLogin,
  getUser: (state) => state.user,
  getErrorData: (state) => state.errorData,
  getStatusData: (state) => state.statusData,
  getselectedRunId: (state) => state.selectedRunId,
  getstatsdata: (state) => state.stats,
  getiserror: (state) => state.iserror,
};

/*
 * Inside the action we are handling all the api calls and storing the data in state
 */
const actions = {
  /*
   * Login to the application which accepts username and passwprd as a object
   */
  login({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/authenticate", payload)
        .then((response) => {
          if (response.data && response.status === 200) {
            setCookie("recon", response.data.access_token, 15);
            setCookie("reconun", response.data.username, 15);
            setCookie("reconr", response.data.role, 15);
            setCookie("recone", response.data.email, 15);
            commit("USER_LOGEDIN", response.data);
            resolve(response.data);
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          commit("SET_ERROR_DATA", err.response.data);
          commit("SET_IF_ERROR");
          reject(err.response.data);
        });
    });
  },

  /*
   * Dwonload the computed result zip folder from the api.
   * This accepts run_id as a parameter.
   */
  downloadfile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/download?run_id=${payload}`, {
          responseType: "blob",
        })
        .then((response) => {
          if (response.status === 200) {
            var filename = "";
            var disposition = response.headers["content-disposition"];
            if (disposition && disposition.indexOf("attachment") !== -1) {
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(disposition);
              if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, "");
              }
            }
            FileSaver.saveAs(response.data, filename);
            resolve(response);
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          commit("SET_ERROR_DATA", err.response.data);
          commit("SET_IF_ERROR");
          reject(err.response.data);
        });
    });
  },

  /*
   * Log out from the app.
   */
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/logout")
        .then((response) => {
          if (response.data && response.status === 200) {
            commit("USER_LOGEDOUT");
            resolve(response.data);
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          commit("SET_ERROR_DATA", err.response.data);
          commit("SET_IF_ERROR");
          reject(err.response.data);
        });
    });
  },

  /*
   * Getting the overall status of all the runs.
   * This accepts a object which will have the pagination data.
   * The object contains page, limit and status (if you want tofilter by status)
   */
  getstatus({ commit }, payload) {
    let url = buildurl(payload);
    return new Promise((resolve, reject) => {
      axios
        .get(`/document${url}`)
        .then((response) => {
          if (response.data && response.status === 200) {
            commit("SET_STATUS_DATA", response.data);
            resolve(response.data);
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          commit("SET_ERROR_DATA", err.response.data);
          commit("SET_IF_ERROR");
          reject(err.response.data);
        });
    });
  },

  /*
   * This will give the overview data.
   * This accepts a object which will have the pagination data and run_id.
   * The object contains page, limit and run_id
   */
  getstatsresult({ commit }, payload) {
    let url = buildurl(payload);
    return new Promise((resolve, reject) => {
      axios
        .get(`/stats${url}`)
        .then((response) => {
          if (response.data && response.status === 200) {
            commit("SET_STATS_DATA", response.data);
            resolve(response.data);
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          commit("SET_ERROR_DATA", err.response.data);
          commit("SET_IF_ERROR");
          reject(err.response.data);
        });
    });
  },

  /*
   * This will get the source data.
   * This accepts a object which will have the pagination data and can filter by parameter and company code.
   * The object contains page, limit and parameter, companycode
   */
  getsourcedata({ commit }, payload) {
    let url = buildurl(payload);
    return new Promise((resolve, reject) => {
      axios
        .get(`/sourcedata${url}`)
        .then((response) => {
          if (response.data && response.status === 200) {
            resolve(response.data);
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          commit("SET_ERROR_DATA", err.response.data);
          commit("SET_IF_ERROR");
          reject(err.response.data);
        });
    });
  },

  /*
   * This will get the target data.
   * This accepts a object which will have the pagination data and can filter by parameter and company code.
   * The object contains page, limit and parameter, companycode
   */
  gettargetdata({ commit }, payload) {
    let url = buildurl(payload);
    return new Promise((resolve, reject) => {
      axios
        .get(`/targetdata${url}`)
        .then((response) => {
          if (response.data && response.status === 200) {
            resolve(response.data);
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          commit("SET_ERROR_DATA", err.response.data);
          commit("SET_IF_ERROR");
          reject(err.response.data);
        });
    });
  },

  /*
   * This will get the diffrence data.
   * This accepts a object which will have the pagination data and can filter by parameter and company code.
   * The object contains page, limit and parameter, companycode
   */
  getdiffdata({ commit }, payload) {
    let url = buildurl(payload);
    return new Promise((resolve, reject) => {
      axios
        .get(`/diff${url}`)
        .then((response) => {
          if (response.data && response.status === 200) {
            resolve(response.data);
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          commit("SET_ERROR_DATA", err.response.data);
          commit("SET_IF_ERROR");
          reject(err.response.data);
        });
    });
  },

  /*
   * This api will start the compute process in backend.
   */
  compute({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/compute", payload)
        .then((response) => {
          if (response.data && response.status === 200) {
            resolve(response.data);
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          commit("SET_ERROR_DATA", err.response.data);
          commit("SET_IF_ERROR");
          reject(err.response.data);
        });
    });
  },

  /*
   * This api is used to upload all source, target and mapping files.
   * Requires every files as a zip folder.
   * Accepts form data
   */
  uploadFiles({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/uploadfiles", payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data && response.status === 200) {
            resolve(response.data);
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          commit("SET_ERROR_DATA", err.response.data);
          commit("SET_IF_ERROR");
          reject(err.response.data);
        });
    });
  },

  /*
   * Api for registering a new user.
   * To register a new user one master user should login.
   * This accepts a object whicn contains username, email and password
   */
  registeruser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/registration", payload)
        .then((response) => {
          if (response.data && response.status === 200) {
            resolve(response.data);
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          commit("SET_ERROR_DATA", err.response.data);
          commit("SET_IF_ERROR");
          reject(err.response.data);
        });
    });
  },
};

/*
 * Mutations used to change the state.
 */
const mutations = {
  USER_LOGEDIN(state, data) {
    state.isLogin = true;
    state.user = data;
    state.userToken = data.access_token;
  },
  USER_LOGEDIN_COOKIE(state, data) {
    state.isLogin = true;
    state.user = data;
  },
  USER_LOGEDOUT(state) {
    state.isLogin = false;
    state.user = {};
    state.userToken = "";
  },
  SET_ERROR_DATA(state, data) {
    state.errorData = data;
  },
  SET_NO_ERROR_DATA(state) {
    state.errorData = "";
  },
  SET_IF_ERROR(state) {
    state.iserror = true;
  },
  SET_NOT_ERROR(state) {
    state.iserror = false;
  },
  SET_STATUS_DATA(state, data) {
    state.statusData = data;
  },
  SET_SELECTED_RUN_ID(state, data) {
    state.selectedRunId = data;
  },
  SET_STATS_DATA(state, data) {
    state.stats = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
