/*
 * This is the entry of the store. 
 * We are using vuex to store the data which will be commnicated through the diffrent page.
 */

import Vue from "vue";
import Vuex from "vuex";
import app from './modules/app';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        app,
    }
})

