<template>
  <v-app>
    <v-main class="main-container">
      <v-app-bar class="main-appbar" dense elevation="5" height="60" outlined>
        <div class="header-content">
          <div>
            <svg
              data-v-7ba5bd90=""
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="auto"
              height="auto"
              viewBox="50 0 350 60"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                data-v-7ba5bd90=""
                transform="translate(0.000000,100.000000) scale(0.082645,-0.067568)"
                fill="#000000"
                stroke="none"
                width="100%"
                height="100%"
              >
                <path
                  data-v-7ba5bd90=""
                  d="M 590 844 L 590 364.681 L 681.852 363.537 L 772.705 362.393 L 821.721 364.089 C 1144.721 562.089 1543.345 956.654 1923.77 1119.727 C 2254.754 1261.606 3126.978 1529.669 3366.978 1540.669 C 3446.978 1544.669 3808.57 1613.829 3822.57 1617.829 C 3836.57 1621.829 2762.476 1609.253 2024.476 1609.253 L 596.776 1601.965 L 590 844 Z"
                  width="100%"
                  height="100%"
                ></path>
              </g>
            </svg>
          </div>
          <div class="img-container">
            <img class="image-con" src="../src/assets/Logo.jpg" />
          </div>
          <div class="header-title">Astron Technologies</div>
        </div>
        <div class="router-button-container" v-if="true">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <router-link class="home-button" to="/register">
                <v-avatar class="header-avtar" v-bind="attrs" v-on="on">
                  <v-icon dark> person_add </v-icon>
                </v-avatar>
              </router-link>
            </template>
            <span>Register User</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <router-link class="home-button" to="/status">
                <v-avatar class="header-avtar" v-bind="attrs" v-on="on">
                  <v-icon dark> dataset </v-icon>
                </v-avatar>
              </router-link>
            </template>
            <span>Dashboard</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <router-link class="home-button" to="/home">
                <v-avatar class="header-avtar" v-bind="attrs" v-on="on">
                  <v-icon dark> home </v-icon>
                </v-avatar>
              </router-link>
            </template>
            <span>Home</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-bind="attrs"
                v-on="on"
                id="loginavtar"
                class="login-button header-avtar"
                @click="userDataShow"
              >
                <v-icon dark v-if="!getisLogin"> person </v-icon>
                <span v-if="getisLogin" class="white--text text-h5">{{
                  useravatar
                }}</span>
              </v-avatar>
            </template>
            <span>User</span>
          </v-tooltip>
        </div>
        <div id="userprofile"  v-if="showUserData">
          <v-card
            class="mx-auto my-12 user-data-card"
            max-width="374"
            elevation="5"
          >
            <div class="user-header">{{ getUser.username }}</div>
            <div class="user-text">{{ getUser.role }}</div>
            <div class="user-text">{{ getUser.email }}</div>

            <div class="user-btn-div">
              <v-btn class="mr-4 logout-btn" @click="logoutaction">
                log out
              </v-btn>
            </div>
          </v-card>
        </div>
      </v-app-bar>
      <v-card class="toolbar-card">
        <v-toolbar class="toolbar-custum" flat>
          <div class="toolbar-header">{{ currentRouteName }}</div>
        </v-toolbar>
        <v-divider></v-divider>
      </v-card>
      <v-container>
        <router-view></router-view>
      </v-container>
      <div class="footer" v-if="routeName == 'Home'">
        <div class="footer-content">
          <div class="left-btn-container">
            <button>Contact Us</button>
            <button>Help</button>
            <button @click="logoutaction">Log Out</button>
            <button id="contactus">Terms & Conditions</button>
            <button id="help">Help</button>
          </div>
        </div>
        <div class="footer-copy">
          <span id="cpoyright-text"
            >@2020-2022 Astron Technologies, All Rights Reserved.</span
          >
        </div>
        <div class="footer-footer"></div>
      </div>
      <v-bottom-sheet v-model="getiserror">
        <v-sheet class="text-center" height="200px">
          <v-btn class="mt-6" text color="red" @click="hideerror">
            close
          </v-btn>
          <div class="py-3">
            {{ getErrorData }}
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "App",

  components: {},

  data: () => ({
    showUserData: false,
    showsheet: true,
  }),

  computed: {
    ...mapGetters("app", [
      "getisLogin",
      "getUser",
      "getiserror",
      "getErrorData",
    ]),
    useravatar() {
      let name = this.getUser.username.split(" ");
      let avtar;
      if (name[0]) {
        avtar = name[0].substring(0, 1).toUpperCase();
      }
      if (name[1]) {
        avtar = avtar + name[1].substring(0, 1).toUpperCase();
      }
      return avtar;
    },
    currentRouteName() {
      if (this.$route.name == "Home") {
        return "Recon Dashboard";
      }
      if (this.$route.name == "Status") {
        return "Status Dashboard";
      }
      if (this.$route.name == "Register") {
        return "Register User";
      }
      if (this.$route.name == "Login") {
        return "Login";
      }
      if (this.$route.name == "Result") {
        return "Data Dashboard";
      }
      return this.$route.name;
    },
    routeName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions("app", ["logout"]),
    ...mapMutations("app", ["SET_NOT_ERROR", "SET_NO_ERROR_DATA"]),
    userDataShow() {
      this.showUserData = true;
    },
    async logoutaction() {
      this.showUserData = false;
      await this.logout();
      document.cookie =
        "recon=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie =
        "reconun=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie =
        "reconr=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie =
        "recone=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      this.$router.push("/");
    },
    hideerror() {
      this.SET_NOT_ERROR();
      this.SET_NO_ERROR_DATA();
    },
  },
  mounted() {
    document.addEventListener("click", (event) => {
      let userprofile = document.querySelector("#userprofile");
      let loginavtar = document.querySelector("#loginavtar");
      if (
        userprofile &&
        !userprofile.contains(event.target) &&
        !loginavtar.contains(event.target)
      ) {
        this.showUserData = false;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
@import "./styles/global.scss";

.v-application {
  font-family: $base-font-family !important;
}

a {
  text-decoration: none !important;
}
.header-content {
  display: flex;
}
.header-title {
  color: $base-color-primary;
  position: absolute;
  font-size: 22px;
  left: 155px;
  top: 18px;
  font-weight: bold;
  margin: 5px;
}

.header-image {
  height: 35px;
  border-radius: 2px;
}
.router-button-container {
  position: absolute;
  right: 30px;
}
.home-button {
}
.login-button {
}
.main-container {
}

.header-avtar {
  width: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  margin: 0 20px 0 0;
  background-color: $base-color-secondry;
}

.user-data {
}

.user-data-card {
  width: 200px;
  padding: 0 0 10px 0;
  position: absolute !important;
  top: 20px;
  right: 50px;
  z-index: 999;
}

.user-header {
  height: 30px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  text-align: center;
  background-color: rgb(223, 222, 222);
  margin: 0 0 10px 0;
}

.user-text {
  text-align: center;
}
.logout-btn {
  background-color: $base-color-primary !important;
  color: #fff;
}
.user-btn-div {
  text-align: center;
}

.image-con {
  margin: 3px 0;
  height: 40px;
}
.toolbar-card {
  margin: 5px 20px !important;
  height: 40px !important;
  position: unset;
}
.toolbar-header {
  margin: 0 7%;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  color: $base-color-secondry;
}
.toolbar-custum {
  height: 40px !important;
  display: contents;
}
.main-appbar {
  background-color: #fff !important;
  padding-left: 0 !important ;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  background-color: $base-color-primary;
  color: white;
  text-align: center;

  .footer-content {
    height: 46px;

    .left-btn-container {
      height: 30px;

      button {
        color: $base-color-sec;
        width: 100px;
        text-decoration: underline;
        font-weight: 500;
        margin-top: 5px;
        float: left;
      }

      button:hover {
        color: aqua;
      }

      #contactus {
        float: right;
        width: 180px;
        margin-right: 20px;
      }
      #help {
        float: right;
      }
    }
  }

  .footer-copy {
    height: 30px;

    #cpoyright-text {
      float: right;
      font-size: 12px;
      margin: 0 20px 10px 0;
      color: $base-color-sec;
    }
  }

  .footer-footer {
    height: 4px;
    background-color: red;
  }
}
.img-container {
  position: absolute;
  top: 15px;
  left: 120px;
}
</style>
<style>
.toolbar-custum > div {
  height: 40px !important;
}
.main-appbar > div {
  padding-left: 0 !important;
}
</style>
