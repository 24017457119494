<!-- 
  In this page we are showing the status of all the computed runs
-->
<template>
  <div>
    <div class="refresh-button">
      <v-btn  class="mr-4" @click="refresh"> Refresh </v-btn>
    </div>
    <div class="table-container-status">
      <v-data-table
        :headers="headers"
        :items="statusdata"
        :loading="statusdataloading"
        loading-text="Loading... Please wait"
        :options="statusoptions"
        :server-items-length="totalstatusdata"
        @pagination="changedpagination"
        class="elevation-1"
        :footer-props="footeroption"
      >
        <template v-slot:[`item.status`]="{ item }">
          <v-chip :color="getColor(item.status)" dark>
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            :disabled="item.status.toLowerCase() != 'completed'"
            color="#00679E"
            @click="showresult(item)"
            tile
          >
            <v-icon dark>open_in_new</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "View-Status",

  components: {},

  data() {
    return {
      headers: [
        { text: "Run Id", value: "runid", align: "center", sortable: false },
        { text: "Status", value: "status", align: "center", sortable: false },
        { text: "Start Date & Time", value: "startDate", align: "center", sortable: false },
        { text: "End Date & Time", value: "endDate", align: "center", sortable: false },
        { text: "Source Records", value: "sourceRecords", align: "center", sortable: false,},
        { text: "Target Records", value: "targetRecords", align: "center", sortable: false },
        { text: "Comany Code", value: "companyCodes", align: "center", sortable: false },
        { text: "Details", value: "actions", sortable: false, align: "center" },
      ],
      statusdata: [],
      statusdataloading: true,
      statusoptions: {
        itemsPerPage: 10,
      },
      totalstatusdata: 0,
      payloaddata: {
        page: 1,
        limit: 10,
        status: "",
        parameter: "",
        paramid: "",
        run_id: "",
      },
      footeroption: {
        "items-per-page-options": [5, 10, 15, 20],
      },
    };
  },
  computed: {
    ...mapGetters("app", ["getStatusData", "getselectedRunId"]),
  },
  methods: {
    ...mapActions("app", ["getstatus"]),
    ...mapMutations("app", ["SET_SELECTED_RUN_ID"]),
    // Adding colors to each status
    getColor(status) {
      if (status.toLowerCase() == "error") return "red";
      else if (status.toLowerCase() == "completed") return "#00679E";
      else if (status.toLowerCase() == "progress") return "orange";
      else return "#00679E";
    },
    changedpagination(data) {
      this.payloaddata.limit = data.itemsPerPage;
      this.payloaddata.page = data.page;
      this.statusapicall(this.payloaddata);
    },
    showresult(item) {
      this.SET_SELECTED_RUN_ID(item);
      this.$router.push("/result");
    },
    // refresh the table
    refresh() {
      this.payloaddata.page = 1;
      this.statusdataloading = true;
      this.statusapicall(this.payloaddata);
    },
    async statusapicall(payload) {
      let response = await this.getstatus(payload);
      if (response) {
        this.statusdataloading = false;
        this.statusdata = [];
        this.totalstatusdata = response.totalDocs;

        for (let i = 0; i < response.docs.length; i++) {
          let obj = {};
          obj.runid = response.docs[i].run_id;
          if (response.docs[i].status.toLowerCase() == "error") {
            obj.status = "Error";
          } else if (response.docs[i].status.toLowerCase() == "completed") {
            obj.status = "completed";
          } else if (response.docs[i].status.toLowerCase() == "in_progress") {
            obj.status = "Progress";
          } else {
            obj.status = response.docs[i].status;
          }

          obj.startDate = response.docs[i].start_date;
          obj.endDate = response.docs[i].end_date;
          obj.sourceRecords = response.docs[i].no_of_source_records;
          obj.targetRecords = response.docs[i].no_of_target_records;
          obj.companyCodes = response.docs[i].no_of_company_codes;
          this.statusdata.push(obj);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../styles/global.scss';
a {
  text-decoration: none !important;
}
.table-container-status {
  margin: 20px 0 0 0;
}
.v-btn__content {
  position: unset !important;
}

.v-icon.v-icon {
  position: unset !important;
}

.v-btn {
  position: unset !important;
}
.refresh-button{
  button {
    background-color: $base-color-primary !important;
    border-color: $base-color-primary !important;
    color: #fff !important;
  }
}

.v-data-table th {
  font-size: 14px !important;
}
</style>
