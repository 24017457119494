/*
 * This is the basic config of vuetify js
 * We used this for some components on the page like tables and expansion pannels
 * We used material design for our theme
 */
import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "md",
  },
});
