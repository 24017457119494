/*
 * Basic config file for axios which used to handel all the network request
 */

import axios from "axios";
import Vue from "vue";

export default Vue.prototype.$axios = axios.create({
  baseURL: "https://recona.aricord.com/api/v1",
});
