<!-- 
  This is the home page where we can upload our files
-->
<template>
  <div>
    <v-card elevation="4" class="mx-auto top-card" outlined v-if="false">
      <v-container>
        <div class="card-title">Select your project</div>
        <v-row>
          <v-col class="d-flex column-class" cols="12" sm="6">
            <v-btn depressed color="primary"> Select default project </v-btn>
          </v-col>
          <v-col class="d-flex column-class" cols="12" sm="6"> </v-col>
        </v-row>
        <v-row class="secon-row">
          <v-col class="d-flex column-class" cols="12" sm="6">
            <v-select
              v-model="selectedProject"
              :items="projectList"
              label="Select projects to build"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col class="d-flex column-class" cols="12" sm="6"> </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card elevation="4" class="mx-auto bottom-card" outlined>
      <v-container>
        <div class="card-title">Select your files</div>
        <v-row>
          <v-col class="d-flex column-class" cols="12" sm="2">
            <v-chip
              close-icon="mdi-close-outline"
              class="chip-title"
              color="primary"
              label
              >Source Files</v-chip
            >
          </v-col>
          <v-col class="d-flex column-class" cols="12" sm="6">
            <v-file-input
              label="File input"
              outlined
              dense
              v-model="sourcefile"
              accept=".zip,.rar,.7zip"
              @change="selectSourceFiles('source', $event)"
            ></v-file-input>
          </v-col>
          <v-col class="d-flex column-class" cols="12" sm="4"> </v-col>
        </v-row>
        <v-row class="secon-row">
          <v-col class="d-flex column-class" cols="12" sm="2">
            <v-chip
              close-icon="mdi-close-outline"
              class="chip-title"
              color="primary"
              label
              >Mapping Files</v-chip
            >
          </v-col>
          <v-col class="d-flex column-class" cols="12" sm="6">
            <v-file-input
              label="File input"
              outlined
              dense
              v-model="mappingfile"
              accept=".zip,.rar,.7zip"
              @change="selectMappingFiles('mapping', $event)"
            ></v-file-input>
          </v-col>
          <v-col class="d-flex column-class" cols="12" sm="4"> </v-col>
        </v-row>
        <v-row class="secon-row">
          <v-col class="d-flex column-class" cols="12" sm="2">
            <v-chip
              close-icon="mdi-close-outline"
              class="chip-title"
              color="primary"
              label
              >Target Files</v-chip
            >
          </v-col>
          <v-col class="d-flex column-class" cols="12" sm="6">
            <v-file-input
              label="File input"
              outlined
              dense
              v-model="targetfile"
              accept=".zip,.rar,.7zip"
              @change="selectTargetFiles('target', $event)"
            ></v-file-input>
          </v-col>
          <v-col class="d-flex column-class" cols="12" sm="4"> </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card elevation="4" class="mx-auto bottom-card" outlined>
      <v-container>
        <v-row>
          <v-col class="d-flex column-class" cols="12" sm="8"> </v-col>
          <v-col class="d-flex column-class" cols="12" sm="2"> </v-col>
          <v-col class="d-flex column-class" cols="12" sm="2">
            <div class="compute-button">
              <v-btn
              depressed
              :disabled="enablecomputed"
              @click="uploadAllFiles"
            >
              Compute
            </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "View-Home",

  components: {},

  data() {
    return {
      selectedProject: "",
      projectList: ["Projectone", "Projecttwo", "Projectthree", "Projectfour"],
      fileobjecct: {
        source_file: "",
        mapping_file: "",
        target_file: "",
      },
      sourcefileselected: false,
      mappingfileselected: false,
      targetfileselected: false,
      sourcefile: {},
      targetfile: {},
      mappingfile: {},
    };
  },
  computed: {
    ...mapGetters("app", ["isLogin", "getUser"]),
    // Enable the computed button when the user selects all the files
    enablecomputed() {
      return this.sourcefileselected &&
        this.mappingfileselected &&
        this.targetfileselected
        ? false
        : true;
    },
  },
  methods: {
    ...mapActions("app", ["uploadFiles", "compute"]),
    // Selected source files
    selectSourceFiles(data, event) {
      if (event) {
        this.sourcefileselected = true;
      } else {
        this.sourcefileselected = false;
      }
    },

    // Selected mapping files
    selectMappingFiles(data, event) {
      if (event) {
        this.mappingfileselected = true;
      } else {
        this.mappingfileselected = false;
      }
    },

    // Seleted target files
    selectTargetFiles(data, event) {
      if (event) {
        this.targetfileselected = true;
      } else {
        this.targetfileselected = false;
      }
    },

    // Upload all the files and start computing if the we get the response
    async uploadAllFiles() {
      let formdata = new FormData();
      formdata.append("source_file", this.sourcefile, 'source_folder.zip');
      formdata.append("mapping_file", this.mappingfile, 'mapping_folder.zip');
      formdata.append("target_file", this.targetfile, 'target_folder.zip');
      await this.uploadFiles(formdata);
      let dataobj = {
        calcparam1: true,
        calcparam2: true,
      };
      await this.compute(dataobj);
      // After computation go to status page
      this.$router.push("/status");
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../styles/global.scss';
.column-class {
  padding: 0 !important;
}
.secon-row {
  padding: 10px 0 0 0;
}
.project-button {
  margin: 0 !important;
}
.top-card {
  padding-left: 20px;
}
.bottom-card {
  margin-top: 10px;
  padding-left: 20px;
}
.card-title {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  text-align: center;
  word-break: break-all;
  margin-top: -10px;
}
.chip-title {
  min-width: 120px;
}
.v-card {
  position: unset;
}
.compute-button {
  button {
    background-color: $base-color-primary !important;
    border-color: $base-color-primary !important;
    color: #fff !important;
  }
}
</style>
