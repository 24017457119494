<!-- 
  This is the login page
-->
<template>
  <div class="card-section">
    <v-card elevation="6">
      <div class="card-title">Log In</div>
      <div class="card-content">
        <template>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="User Name/E-mail"
              required
              outlined
            ></v-text-field>

            <v-text-field
              v-model="password"
              :rules="passwordRules"
              label="Password"
              required
              outlined
            ></v-text-field>

            <div class="login-button">
              <v-btn
                :disabled="!valid"
                color="success"
                class="mr-4"
                @click="loginAction"
              >
                Login
              </v-btn>
            </div>
          </v-form>
        </template>
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { getusertokencookie } from "../plugins/common";
export default {
  name: "View-Login",

  components: {},

  data: () => ({
    valid: true,
    email: "",
    // Email validation rule
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    // Password validation rule
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) =>
        new RegExp("^(?=.*[0-9])").test(v) ||
        "Password should contain one upper case letter and one number",
    ],
    password: "",
  }),
  computed: {
    ...mapGetters("app", ["getisLogin", "getUser", "getiserror", "getErrorData"]),
  },
  methods: {
    ...mapActions("app", ["registeruser", "login"]),
    ...mapMutations("app", ["SET_IF_ERROR", "SET_ERROR_DATA", "USER_LOGEDIN_COOKIE"]),

    // Api call for login
    async loginAction() {
      let data = {
        email: this.email,
        password: this.password,
      };
      await this.login(data);
      if (this.getisLogin) {
        this.$router.push("/home");
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  mounted() {
    // Checking if the cookie is avaliable then go to home page else redirect to login page
    if (getusertokencookie("recon") !== "") {
      let data = {};
      data["username"] = getusertokencookie("reconun");
      data["role"] = getusertokencookie("reconr");
      data["email"] = getusertokencookie("recone");
      this.USER_LOGEDIN_COOKIE(data);
      this.$router.push("/home");
    }
  },
};
</script>
<style lang="scss" scoped>
@import '../styles/global.scss';
.card-section {
  margin: 30px auto;
  width: 400px;
}
.card-title {
  text-align: center;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  height: 40px;
  background-color: $base-color-primary;
}
.card-content {
  padding: 30px 10px;
}
.login-button {
  text-align: center;

  button {
    background-color: $base-color-primary !important;
    border-color: $base-color-primary !important;
    color: #fff !important;
  }
}
.show-signup{
  padding: 20px;
  text-align: center;
}
</style>
