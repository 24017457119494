/*
 * We built a signle page application and the routing part handeled here.
 * Vue-router used for hadling the route.
 * If you are adding a new page register the page here.
 */

import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import Home from "./components/Home";
import Login from "./components/Login.vue";
import Status from "./components/StatusPage.vue";
import Result from "./components/ResultPage.vue";
import Register from "./components/RegisterUser.vue";

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/status",
    name: "Status",
    component: Status,
  },
  {
    path: "/result",
    name: "Result",
    component: Result,
  },
];

/*
 * Created the route instance which will be used in the main.js file
 */
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
export default router;
