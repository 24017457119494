<!-- 
  This is the page to register a new user
-->
<template>
  <div class="card-section">
    <v-card elevation="6">
      <div class="card-title">Register User</div>
      <div class="card-content">
        <template>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="username"
              label="User Name"
              required
              outlined
            ></v-text-field>

            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="User Name/E-mail"
              required
              outlined
            ></v-text-field>

            <v-text-field
              v-model="password"
              :rules="passwordRules"
              label="Password"
              required
              outlined
            ></v-text-field>

            <v-text-field
              v-model="confirmPassword"
              label="Confirm Password"
              required
              outlined
            ></v-text-field>

            <div class="login-button">
              <v-btn
                :disabled="!valid"
                class="mr-4"
                @click="signup"
              >
                Register
              </v-btn>
            </div>
          </v-form>
        </template>
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "View-Login",

  components: {},

  data: () => ({
    valid: true,
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) =>
        new RegExp("^(?=.*[0-9])").test(v) ||
        "Password should contain one upper case letter and one number",
    ],
    password: "",
    confirmPassword: "",
    username: "",
  }),
  computed: {
    ...mapGetters("app", [
      "getisLogin",
      "getUser",
      "getiserror",
      "getErrorData",
    ]),
  },
  methods: {
    ...mapActions("app", ["registeruser", "login"]),
    ...mapMutations("app", ["SET_IF_ERROR", "SET_ERROR_DATA"]),

    // Api call for registering the new user
    signup() {
      this.$refs.form.validate();
      if (this.password != this.confirmPassword) {
        this.SET_ERROR_DATA("Password doesnot match");
        this.SET_IF_ERROR();
        return;
      }
      let data = {
        email: this.email,
        username: this.username,
        password: this.password,
      };
      this.reguserapi(data);
    },
    async reguserapi(data) {
      const res = await this.registeruser(data);
      if (res) {
        this.$router.push("/home");
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../styles/global.scss';
.card-section {
  margin: 2px auto;
  width: 400px;
}
.card-title {
  text-align: center;
  color: #fff;
  font-size: $card-title-font-size;
  font-weight: bold;
  height: 30px;
  background-color: $base-color-primary;
}
.card-content {
  padding: 30px 10px;
}
.login-button {
  text-align: center;
  button {
    background-color: $base-color-primary !important;
    border-color: $base-color-primary !important;
    color: #fff !important;
  }
}
.show-signup {
  padding: 20px;
  text-align: center;
}
</style>
