<!-- 
  This is the report page where we show all the reports of a particular run
-->
<template>
  <div>
    <div class="action-container">
      <div class="download-contatiner">
        <div class="download-radio">
          <v-radio-group
            v-model="selecteddownloadoption"
            class="radio-group-container"
            column
          >
            <v-radio label="Overview" value="overview"></v-radio>
            <v-radio label="Detail" value="detail"></v-radio>
          </v-radio-group>
        </div>
        <div class="download-button">
          <v-btn class="mr-4" @click="downloadcontent"> Download </v-btn>
        </div>
      </div>
      <div class="selection-container">
        <div class="card-title">Overall Status</div>
        <!-- 
          Showing the overview status
        -->
        <v-expansion-panels v-if="overallresult.length > 0">
          <v-expansion-panel
            v-for="(item, i) in overallresult"
            :key="i"
            focusable
            :class="
              i == 0 && overallresult.length > 1 ? 'first-exp-pannel' : ''
            "
            @click="selectedcompanycode(item)"
          >
            <v-expansion-panel-header>
              <div>
                <div class="exp-header-title" v-if="i == 0">
                  <div class="ov-country-code">Company Code</div>
                  <div class="ov-percentage">Percentage Match</div>
                </div>
                <div class="exp-header-content">
                  <div class="ov-country-code">{{ item.companycode }}</div>
                  <div class="ov-percentage">{{ item.percentage }}</div>
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="exp-content-container">
                <div class="exp-content-title">
                  <div class="ov-country-code">Parameter</div>
                  <div class="ov-percentage">Percentage</div>
                </div>
                <div
                  class="exp-content"
                  :id="`expid-${j}`"
                  v-for="(data, j) in item.parameterstatus"
                  @click="selectedparameter(j + 1, $event)"
                  :key="j"
                >
                  <div class="exp-ov-country-code">{{ data.pname }}</div>
                  <div class="exp-ov-percentage">{{ data.percentage }}</div>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div>
          <!-- 
            Pagination for overview status
          -->
          <v-pagination
            v-if="overallresult.length > 0 && exppaginationlength > 1"
            v-model="paginationpage"
            class="pagination-content"
            :length="exppaginationlength"
            @input="changeinpagination"
          ></v-pagination>
        </div>
      </div>

      <div class="refresh-container">
        <v-btn class="mr-4" @click="refrshpage"> Refresh </v-btn>
      </div>
    </div>
    <v-container class="table-container">
      <v-row>
        <v-col class="column-class" cols="12" sm="6">
          <div class="card-title">Source Data</div>
          <div>
            <v-data-table
              :headers="sourceHeaders"
              :items="sourceData"
              :options="sourceoptions"
              :server-items-length="totalsourcedata"
              @pagination="changedsourcepagination"
              :dense="true"
              class="elevation-1"
              :loading="sourcedataloading"
              :footer-props="footeroption"
              loading-text="Loading... Please wait"
            >
            </v-data-table>
          </div>
        </v-col>
        <v-col class="column-class" cols="12" sm="6">
          <div class="card-title">Target Data</div>
          <div>
            <v-data-table
              :headers="targetHeaders"
              :items="targetData"
              :options="targetoptions"
              :server-items-length="totaltargetdata"
              @pagination="changedtargetpagination"
              :dense="true"
              class="elevation-1"
              :loading="targetdataloading"
              :footer-props="footeroption"
              loading-text="Loading... Please wait"
            >
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="result-table">
      <div id="result-title" class="card-title">Result Data</div>
      <div>
        <v-data-table
          :headers="resultHeaders"
          :items="resultData"
          :options="diffoptions"
          :server-items-length="totaldiffdata"
          @pagination="changeddiffpagination"
          :dense="true"
          class="elevation-1"
          :loading="diffdataloading"
          :footer-props="footeroption"
          loading-text="Loading... Please wait"
        >
        </v-data-table>
      </div>
    </div>
  </div>
</template>
<script>
var XLSX = require("xlsx");
import { mapGetters, mapActions } from "vuex";
export default {
  name: "View-Result",

  components: {},

  data() {
    return {
      sourcedataloading: true,
      targetdataloading: true,
      diffdataloading: true,
      paginationpage: 1,
      exppaginationlength: 5,
      overallresult: [],
      sourceHeaders: [],
      sourceData: [],
      targetHeaders: [],
      targetData: [],
      resultHeaders: [],
      resultData: [],
      selecteddownloadoption: "overview",
      sourceoptions: {
        itemsPerPage: 5,
      },
      targetoptions: {
        itemsPerPage: 5,
      },
      diffoptions: {
        itemsPerPage: 5,
      },
      totalsourcedata: 0,
      totaltargetdata: 0,
      totaldiffdata: 0,
      exceldata: [],
      payloaddata: {
        page: 1,
        limit: 5,
        status: "",
        parameter: "",
        run_id: "",
        companycode: "",
      },
      footeroption: {
        "items-per-page-options": [5, 10, 15, 20],
      },
    };
  },
  computed: {
    ...mapGetters("app", ["getselectedRunId", "getstatsdata"]),
  },
  methods: {
    ...mapActions("app", [
      "getsourcedata",
      "gettargetdata",
      "getdiffdata",
      "getstatsresult",
      "downloadfile",
    ]),

    // Refreshing the page and parameters
    refrshpage() {
      this.paginationpage = 1;
      this.payloaddata.parameter = "";
      this.payloaddata.companycode = "";
      this.payloaddata.run_id = this.getselectedRunId.runid;
      this.payloaddata.item = 5;
      this.payloaddata.page = 1;
      this.getstatsresultapi(this.payloaddata);
      this.changedsourcepagination({ page: 1, itemsPerPage: 5 });
      this.changedtargetpagination({ page: 1, itemsPerPage: 5 });
      this.changeddiffpagination({ page: 1, itemsPerPage: 5 });
    },

    // Downloading the content according to the selection of detail or overview
    async downloadcontent() {
      if (this.selecteddownloadoption == "detail") {
        this.downloadfile(this.getselectedRunId.runid);
      }
      if (this.selecteddownloadoption == "overview") {
        await this.getexceloverview();
        // generate worksheet and workbook
        const worksheet = XLSX.utils.json_to_sheet(this.exceldata);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Overview");

        // Saving the excel file
        XLSX.writeFile(workbook, `${this.getselectedRunId.runid}.xlsx`, {
          compression: true,
        });
      }
    },

    selectedcompanycode(data) {
      this.payloaddata.companycode = data.companycode;
    },

    // Selecting the company code and parametr value when user selects
    selectedparameter(j, event) {
      let expcontents = document.querySelectorAll(".exp-content");
      for (let i = 0; i < expcontents.length; i++) {
        if (expcontents[i].classList.contains("active")) {
          expcontents[i].classList.remove("active");
        }
      }
      if (event.target.parentElement.classList.contains(`exp-content`)) {
        event.target.parentElement.classList.add("active");
      }
      if (j) {
        this.payloaddata.parameter = `Paramater${j}`;
      }

      this.changedsourcepagination({ page: 1, itemsPerPage: 5 });
      this.changedtargetpagination({ page: 1, itemsPerPage: 5 });
      this.changeddiffpagination({ page: 1, itemsPerPage: 5 });
    },

    changeinpagination(num) {
      this.payloaddata.page = num;
      this.payloaddata.limit = 5;
      this.getstatsresultapi(this.payloaddata);
    },

    // Getting the excel data for overview
    async getexceloverview() {
      const data = {
        excel: true,
        run_id: this.getselectedRunId.runid,
      };
      this.exceldata = [];
      let res = await this.getstatsresult(data);
      if (res) {
        for (let i = 0; i < res.docs.length; i++) {
          let excelobj = {};

          let perone = 0;
          let pertwo = 0;
          let perthre = 0;
          let perfour = 0;
          let paramcount = 0;

          excelobj["Company Code"] = res.docs[i]["Company Code"];
          excelobj["Percentage Match"] = 0;

          if (res.docs[i]["Percentage Parameter1"]) {
            perone = res.docs[i]["Percentage Parameter1"];
            perone = parseInt(perone.substring(0, perone.length - 1));
            excelobj[`${res.docs[i]["Paramater1"]} Match`] =
              res.docs[i]["Percentage Parameter1"];
            paramcount = paramcount + 1;
          }
          if (res.docs[i]["Percentage Parameter2"]) {
            pertwo = res.docs[i]["Percentage Parameter2"];
            pertwo = parseInt(pertwo.substring(0, pertwo.length - 1));
            excelobj[`${res.docs[i]["Paramater2"]} Match`] =
              res.docs[i]["Percentage Parameter2"];
            paramcount = paramcount + 1;
          }
          if (res.docs[i]["Percentage Parameter3"]) {
            perthre = res.docs[i]["Percentage Parameter3"];
            perthre = parseInt(perthre.substring(0, perthre.length - 1));
            excelobj[`${res.docs[i]["Paramater3"]} Match`] =
              res.docs[i]["Percentage Parameter3"];
            paramcount = paramcount + 1;
          }
          if (res.docs[i]["Percentage Parameter4"]) {
            perfour = res.docs[i]["Percentage Parameter4"];
            perfour = parseInt(perfour.substring(0, perfour.length - 1));
            excelobj[`${res.docs[i]["Paramater4"]} Match`] =
              res.docs[i]["Percentage Parameter4"];
            paramcount = paramcount + 1;
          }

          let totalp = Math.ceil(
            (perone + pertwo + perthre + perfour) / paramcount
          );
          excelobj["Percentage Match"] = `${totalp}%`;

          this.exceldata.push(excelobj);
        }
      }
    },

    // Getting the data for overview
    async getstatsresultapi(payload) {
      payload.parameter = "";
      payload.companycode = "";

      this.overallresult = [];
      let res = await this.getstatsresult(payload);
      if (res) {
        this.exppaginationlength = Math.ceil(res.totalDocs / 5);
        for (let i = 0; i < res.docs.length; i++) {
          let obj = {};
          obj["companycode"] = res.docs[i]["Company Code"];

          let perone = 0;
          let pertwo = 0;
          let perthre = 0;
          let perfour = 0;
          let paramcount = 0;
          let data = [];

          if (res.docs[i]["Percentage Parameter1"]) {
            perone = res.docs[i]["Percentage Parameter1"];
            perone = parseInt(perone.substring(0, perone.length - 1));
            data.push({
              pname: res.docs[i]["Paramater1"],
              percentage: res.docs[i]["Percentage Parameter1"],
            });
            paramcount = paramcount + 1;
          }
          if (res.docs[i]["Percentage Parameter2"]) {
            pertwo = res.docs[i]["Percentage Parameter2"];
            pertwo = parseInt(pertwo.substring(0, pertwo.length - 1));
            data.push({
              pname: res.docs[i]["Paramater2"],
              percentage: res.docs[i]["Percentage Parameter2"],
            });
            paramcount = paramcount + 1;
          }
          if (res.docs[i]["Percentage Parameter3"]) {
            perthre = res.docs[i]["Percentage Parameter3"];
            perthre = parseInt(perthre.substring(0, perthre.length - 1));
            data.push({
              pname: res.docs[i]["Paramater3"],
              percentage: res.docs[i]["Percentage Parameter3"],
            });
            paramcount = paramcount + 1;
          }
          if (res.docs[i]["Percentage Parameter4"]) {
            perfour = res.docs[i]["Percentage Parameter4"];
            perfour = parseInt(perfour.substring(0, perfour.length - 1));
            data.push({
              pname: res.docs[i]["Paramater4"],
              percentage: res.docs[i]["Percentage Parameter4"],
            });
            paramcount = paramcount + 1;
          }

          // Calculation to show the avrage percentage
          let totalp = Math.ceil(
            (perone + pertwo + perthre + perfour) / paramcount
          );
          obj["percentage"] = `${totalp}%`;

          obj["parameterstatus"] = data;
          this.overallresult.push(obj);
        }
      }
    },

    // Getting the source data
    async getsourcedataapi(payload) {
      this.sourcedataloading = true;
      this.sourceData = [];
      this.sourceHeaders = [];
      let res = await this.getsourcedata(payload);
      if (res) {
        this.sourcedataloading = false;
        this.totalsourcedata = res.totalDocs;
        for (let i = 0; i < res.docs.length; i++) {
          let obj = {};
          for (const [key, value] of Object.entries(res.docs[i])) {
            let headerobj = {};

            if (
              key != "Company Code" &&
              key != "Unique Id" &&
              key != "Year/Month" &&
              key != "_id" &&
              key != "created_at" &&
              key != "run_id" &&
              key != "updated_at" &&
              key != "user_id" &&
              key != "Amount"
            ) {
              obj[key] = value;
              if (i == 0) {
                headerobj["text"] = key;
                headerobj["value"] = key;
                headerobj["sortable"] = false;
                this.sourceHeaders.push(headerobj);
              }
            }

            if (key == "Company Code") {
              obj[key] = value;
            }

            if (key == "Amount") {
              obj[key] = value;
            }
          }
          this.sourceData.push(obj);
        }
        this.sourceHeaders.push({
          text: "Amount",
          value: "Amount",
          sortable: false,
        });
        this.sourceHeaders.unshift({
          text: "Company Code",
          value: "Company Code",
          sortable: false,
        });
      }
    },

    //Getting the target data
    async gettargetdataapi(payload) {
      this.targetData = [];
      this.targetHeaders = [];
      this.targetdataloading = true;
      let res = await this.gettargetdata(payload);
      if (res) {
        this.targetdataloading = false;
        this.totaltargetdata = res.totalDocs;
        for (let i = 0; i < res.docs.length; i++) {
          let obj = {};
          for (const [key, value] of Object.entries(res.docs[i])) {
            let headerobj = {};
            if (
              key != "Company Code" &&
              key != "Unique Id" &&
              key != "Year/Month" &&
              key != "_id" &&
              key != "created_at" &&
              key != "run_id" &&
              key != "updated_at" &&
              key != "user_id" &&
              key != "Amount"
            ) {
              obj[key] = value;
              if (i == 0) {
                headerobj["text"] = key;
                headerobj["value"] = key;
                headerobj["sortable"] = false;
                this.targetHeaders.push(headerobj);
              }
            }

            if (key == "Company Code") {
              obj[key] = value;
            }
            if (key == "Amount") {
              obj[key] = value;
            }
          }
          this.targetData.push(obj);
        }
        this.targetHeaders.push({
          text: "Amount",
          value: "Amount",
          sortable: false,
        });
        this.targetHeaders.unshift({
          text: "Company Code",
          value: "Company Code",
          sortable: false,
        });
      }
    },

    // Getting the diffrence data
    async getdiffdataapi(payload) {
      this.resultData = [];
      this.resultHeaders = [];
      this.diffdataloading = true;
      let res = await this.getdiffdata(payload);
      if (res) {
        this.diffdataloading = false;
        this.totaldiffdata = res.totalDocs;
        for (let i = 0; i < res.docs.length; i++) {
          let obj = {};
          for (const [key, value] of Object.entries(res.docs[i])) {
            let headerobj = {};
            if (
              key != "Company Code" &&
              key != "Unique Id" &&
              key != "Year/Month" &&
              key != "_id" &&
              key != "created_at" &&
              key != "run_id" &&
              key != "updated_at" &&
              key != "user_id" &&
              key != "Amount"
            ) {
              obj[key] = value;
              if (i == 0) {
                headerobj["text"] = key;
                headerobj["value"] = key;
                headerobj["sortable"] = false;
                this.resultHeaders.push(headerobj);
              }
            }

            if (key == "Company Code") {
              obj[key] = value;
              if (i == 0) {
                headerobj["text"] = key;
                headerobj["value"] = key;
                headerobj["sortable"] = false;
                this.resultHeaders.unshift(headerobj);
              }
            }
            if (key == "Amount") {
              obj[key] = value;
              if (i == 0) {
                headerobj["text"] = key;
                headerobj["value"] = key;
                headerobj["sortable"] = false;
                this.resultHeaders.push(headerobj);
              }
            }
          }
          this.resultData.push(obj);
        }
      }
    },

    changedsourcepagination(data) {
      this.payloaddata.run_id = this.getselectedRunId.runid;
      this.payloaddata.page = data.page;
      this.payloaddata.limit = data.itemsPerPage;
      this.getsourcedataapi(this.payloaddata);
    },
    changedtargetpagination(data) {
      this.payloaddata.run_id = this.getselectedRunId.runid;
      this.payloaddata.page = data.page;
      this.payloaddata.limit = data.itemsPerPage;
      this.gettargetdataapi(this.payloaddata);
    },
    changeddiffpagination(data) {
      this.payloaddata.run_id = this.getselectedRunId.runid;
      this.payloaddata.page = data.page;
      this.payloaddata.limit = data.itemsPerPage;
      this.getdiffdataapi(this.payloaddata);
    },
  },
  mounted() {
    this.payloaddata.run_id = this.getselectedRunId.runid;
    this.getstatsresultapi(this.payloaddata);
  },
};
</script>
<style lang="scss">
@import "../styles/global.scss";
a {
  text-decoration: none !important;
}
.table-container {
  /* width */
  ::-webkit-scrollbar {
    width: 3px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(185, 184, 184);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .v-data-table {
    max-width: 550px !important;
  }
  .v-data-table__wrapper {
    overflow-y: scroll !important;
    max-height: 150px !important;
  }
}
.v-data-footer {
  height: 40px !important;
}
.v-data-footer__select {
  height: 30px !important;
}

.v-data-table th {
  font-size: 14px !important;
}

.card-title {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.0125em;
  color: $base-color-primary;
  text-align: center;
}
#result-title {
  text-align: center;
}

.action-container {
  display: flex;
}

.download-contatiner {
  padding: 40px 0 0 20px;
  width: 250px;
}
.selection-container {
  width: 600px;
}
.card-header {
  padding: 10px;
  display: flex;
  height: 60px;
}
.final-percentage {
  margin: 0 0 0 30px;
  width: 200px;
  height: 50px;
}
.percengae-header {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.0125em;
  color: $base-color-secondry;
  word-break: break-all;
}
.percengae-text {
  text-align: center;
}
.percentage-card-content {
  width: 400px;
  margin: 0 auto 10px auto;

  tr {
    background-color: #f3f3f3;
  }
}

.theme--light.v-data-table .v-data-table__empty-wrapper {
  color: $base-color-secondry !important;
}

.exp-header-content {
  display: flex;
  color: $base-color-secondry;
  font-size: 16px;
}

.exp-header-title {
  margin: 3px 0 3px 0;
  display: flex;
  font-size: 18px;
}

.exp-content {
  width: 400px;
  display: flex;
  font-size: 12px;
  border-top: 0.5px solid #c3c3c3;
  border-right: 0.5px solid #c3c3c3;
  border-left: 0.5px solid #c3c3c3;
  margin: auto;
  color: $base-color-secondry;
  cursor: pointer;
}

.ov-country-code {
  width: 50%;
  text-align: center;
}
.ov-percentage {
  width: 50%;
  text-align: center;
}

.exp-ov-country-code {
  width: 50%;
  text-align: center;
}
.exp-ov-percentage {
  width: 50%;
  text-align: center;
}
.v-expansion-panel-header {
  padding: 0 !important;
  min-height: 35px;
  height: 35px;
}
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 40px;
  height: 40px;
}
.v-expansion-panel-content__wrap {
  padding: 0 60px;
}
.pagination-content {
  margin: 5px 0 0 0 !important;
}
.exp-content-container {
  padding: 5px 0 10px 0;
}
.exp-content-title {
  display: flex;
  font-size: 14px !important;
}
.exp-content:last-child {
  border-bottom: 0.5px solid #c3c3c3;
}

.exp-content:hover {
  background-color: #d0d0d1;
}

.exp-content.active {
  background-color: #bfe1ff;
}
.download-radio {
  width: 150px;
}
.radio-group-container {
  margin: 0 !important;
}
.download-button {
  button {
    background-color: $base-color-primary !important;
    border-color: $base-color-primary !important;
    color: #fff !important;
  }
}
.refresh-container {
  padding: 55px 0 0 40px;

  button {
    background-color: $base-color-primary !important;
    border-color: $base-color-primary !important;
    color: #fff !important;
  }
}
.v-expansion-panels {
  min-width: 520px;
}

.v-btn__content {
  position: unset !important;
}

.v-icon.v-icon {
  position: unset !important;
}

.v-btn {
  position: unset !important;
}
.first-exp-pannel {
  height: 45px !important;
  min-height: 45px !important;
}
</style>
